.m-video-silder {
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  height: 0.05661232rem;
  position: relative;
  cursor: pointer;
}
.m-video-inside {
  height: 100%;
  width: 0;
  background: #38f;
}
.m-video-point {
  background: #fff;
  box-shadow: 0 0 0.05661232rem 0.05661232rem rgba(0, 0, 0, 0.2);
  height: 0.66047705rem;
  width: 0.66047705rem;
  position: absolute;
  left: -0.33023853rem;
  top: 50%;
  margin-top: -0.33023853rem;
  cursor: pointer;
  border-radius: 50%;
  z-index: 2;
}
.m-video-middle {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
